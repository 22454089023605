<template>
  <!-- 开通Vip相关Model -->
  <el-dialog
    :visible.sync="$store.state.vip.paySucceTipsStatus"
    :close-on-click-modal="false"
    customClass="paySucceClass"
    :top="'0'"
  >
    <div class="bg-wrap"></div>
    <div class="tips-text">{{ $t("paymentSuccessful") }}</div>
  </el-dialog>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      timer: null,
    };
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.setPaySucceTipsStatus(false);
    }, 3000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    ...mapMutations(["setPaySucceTipsStatus"]),
  },
};
</script>
<style lang="scss" scoped>
:deep .paySucceClass {
  width: 372px;
  height: 244px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  .el-dialog__headerbtn {
    padding: 10px;
    z-index: 100;
    margin-top: -10px;
  }

  .el-dialog__body {
    height: calc(100% - 30px);
    text-align: center;
    font-size: 18px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 12px;

    .bg-wrap {
      flex: 1;
      background: url("../../../assets/images/pay/ps.png") no-repeat;
      background-size: contain;
      background-position: center;
      margin-block: 8px;
    }

    .tips-text {
      margin-top: auto;
    }
  }
}
</style>
