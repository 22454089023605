var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.$store.state.vip.paySucceTipsStatus,
        "close-on-click-modal": false,
        customClass: "paySucceClass",
        top: "0"
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(_vm.$store.state.vip, "paySucceTipsStatus", $event)
        }
      }
    },
    [
      _c("div", { staticClass: "bg-wrap" }),
      _c("div", { staticClass: "tips-text" }, [
        _vm._v(_vm._s(_vm.$t("paymentSuccessful")))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }